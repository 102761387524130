@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

h1, h2, h3{
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
}
